import React from 'react';

const BOARD_OFFSET_x = 0.07;
const BOARD_SPACE_x = 5.48;

const BOARD_OFFSET_y = 0.07;
const BOARD_SPACE_y = 5.53;

interface lProps {
  type: string[];
  x: number;
  y: number;
}

const stone = ({ type, x, y }: lProps) => {
  return (
    <div
      className="absolute w-[1.65rem] h-[1.65rem] rounded-full z-0 border border-black"
      key={`${x}${y}`}
      style={{
        backgroundColor: type.includes('prev') ? 'red' : type.includes('white') ? 'white' : 'black',
        left: `${x * BOARD_SPACE_x + BOARD_OFFSET_x}%`,
        top: `${y * BOARD_SPACE_y + BOARD_OFFSET_y}%`,
        opacity: type.includes('hint') ? '0.5' : '1',
        width: type.includes('prev') ? '0.8rem' : '1.65rem',
        height: type.includes('prev') ? '0.8rem' : '1.65rem',
        transform: 'translate(-50%, -50%)',
        borderWidth: type.includes('prev') ? '0px' : '1px',
        zIndex: type.includes('hint') ? '10' : '15',
      }}
    ></div>
  );
};

const MemoriedStone = React.memo(stone);

export default MemoriedStone;
