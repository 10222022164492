import React from 'react';
import logo from './../images/logo.png';

function AboutContent() {
  return (
    <div className="flex w-full justify-center p-6 sm:p-10 md:p-16">
      <div className="w-[45rem] text-lg">
        <p className="inline text-2xl">
          <img src={logo} alt="logo" className="-mt-1 inline h-[1.375rem]" /> 은 일대일 대결에 특화된 오목 서비스입니다.
        </p>
        <br />
        <br />
        <br />
        OMOK은 :
        <br />
        <br />
        <ul className="flex flex-col gap-3">
          <li>
            <p className="text-xl">1. 표준 고모쿠룰(standard gomoku)을 따릅니다.</p>
            6목 이상의 장목이 착수 가능하지만 승리 조건은 아닙니다.
            <br />
            3-3, 4-3, 4-4 모두 가능합니다.
          </li>
          <li className="">
            <p className="text-xl">2. 한 수를 둘 때 5초의 제한시간이 주어집니다. </p>
            5초안에 수를 놓지 않으면 자동으로 패배합니다.
          </li>
          <li>
            <p className="text-xl">3. PC를 통해 접속하는 유저만을 고려하여 제작되었습니다.</p>
            모바일로 접속할 경우 사용이 어려울 수 있습니다.
            <br />
          </li>
        </ul>
        <br />
        <div className="mt-10 w-full border-t border-t-[#898ea4] pt-10 text-center text-sm text-gray-500">© 2023 geniusLHS</div>
      </div>
    </div>
  );
}

export default AboutContent;
