import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import Timer from './Timer';
import Score from './Score';
import Information from './Information';

interface lProps {
  isMyTurn: boolean;
  isBlack: boolean;
  stoneNumber: number;
  username: string;
  room: string;
  winner: string;
  gameState: number;
  socket: Socket;
}

const GomokuModal = ({ isMyTurn, isBlack, stoneNumber, username, room, winner, gameState, socket }: lProps) => {
  const [score, setScore] = useState([0, 0]);

  const [didIRequestRematch, setDidIRequestRematch] = useState(false);
  const [didOpponentRequestRematch, setDidOpponentRequestRematch] = useState(false);

  const [countdownTime, setCountdownTime] = useState(-101);

  useEffect(() => {
    socket.on('alert_score', (data: { score: [number, number] }) => {
      let new_score = data.score;

      setScore(new_score);
    });

    socket.on('receive_request_rematch', (data: { username: string }) => {
      let new_username = data.username;

      if (username !== new_username) setDidOpponentRequestRematch(true);
      if (username === new_username) setDidIRequestRematch(true);
    });

    socket.on('rematch', () => {
      setDidIRequestRematch(false);
      setDidOpponentRequestRematch(false);
    });
  }, []);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      if (!winner) {
        setCountdownTime((prev) => prev - 0.1);
      }
    }, 100);
    return () => clearInterval(countdownTimer);
  }, []);

  useEffect(() => {
    if (!winner && countdownTime < -0.11 && countdownTime > -100) {
      socket.emit('countdown_over', { username: username, room: room, isBlack: isBlack });
      setCountdownTime(-101);
    }
  }, [countdownTime]);

  useEffect(() => {
    if (stoneNumber >= 1) setCountdownTime(Number(process.env.REACT_APP_TIMELIMIT) + 0.9);
  }, [stoneNumber]);

  useEffect(() => {
    if (winner) setCountdownTime(-101);
  }, [winner]);

  const requestRematch = () => {
    socket.emit('request_rematch', { username, room });
  };

  return (
    <div className="flex flex-col justify-between ml-20 font-sans m-0">
      <Timer countdownTime={countdownTime} isMyTurn={isMyTurn}></Timer>
      <Score score={score} username={username} isBlack={isBlack}></Score>
      <Information
        requestRematch={requestRematch}
        isMyTurn={isMyTurn}
        isBlack={isBlack}
        winner={winner}
        gameState={gameState}
        didIRequestRematch={didIRequestRematch}
        didOpponentRequestRematch={didOpponentRequestRematch}
      ></Information>
    </div>
  );
};

export default GomokuModal;
