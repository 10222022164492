import React from 'react';

interface lProps {
  onBoardEnter: any;
  onBoardMove: any;
  onBoardLeave: any;
  onBoardSelect: any;
}

const GomokuBoardDetect = ({ onBoardEnter, onBoardMove, onBoardLeave, onBoardSelect }: lProps) => {
  function getCoord(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    let BOARD_OFFSET_x = -2.7;
    let BOARD_SPACE_x = 5.48;

    let BOARD_OFFSET_y = -2.5;
    let BOARD_SPACE_y = 5.53;

    let coordX = 0;
    let coordY = 0;

    const percentX = (event.nativeEvent.offsetX * 100.0) / (event.target as HTMLDivElement).clientWidth;
    const percentY = (event.nativeEvent.offsetY * 100.0) / (event.target as HTMLDivElement).clientHeight;

    coordX = Math.floor((percentX - BOARD_OFFSET_x) / BOARD_SPACE_x + 0);
    coordY = Math.floor((percentY - BOARD_OFFSET_y) / BOARD_SPACE_y + 0);

    if (coordX < 0) coordX = 0;
    if (coordY < 0) coordY = 0;

    if (coordX > 18) coordX = 18;
    if (coordY > 18) coordY = 18;

    return {
      x: coordX,
      y: coordY,
    };
  }
  return (
    <div
      className="absolute h-[550px] w-[560px] z-20 top-0"
      onMouseEnter={onBoardEnter}
      onMouseMove={(event) => {
        onBoardMove(getCoord(event));
      }}
      onMouseLeave={onBoardLeave}
      onClick={(event) => {
        onBoardSelect(getCoord(event));
      }}
    ></div>
  );
};

export default GomokuBoardDetect;
