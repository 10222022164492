import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
// import Messages from './Messages';
// import SendMessage from './SendMessages';

interface lProps {
  requestRematch: () => void;
  isMyTurn: boolean;
  isBlack: boolean;
  winner: string;
  gameState: number;
  didIRequestRematch: boolean;
  didOpponentRequestRematch: boolean;
}

const Information = ({ requestRematch, isMyTurn, isBlack, winner, gameState, didIRequestRematch, didOpponentRequestRematch }: lProps) => {
  const [isWinner, setIsWinner] = useState(false);

  useEffect(() => {
    setIsWinner((winner === 'black' && isBlack === true) || (winner === 'white' && isBlack === false));
  }, [winner, isBlack]);

  return (
    <div
      className="flex flex-col space-y-3 justify-center items-center h-40 rounded-lg border text-center text-lg border-gray-300 bg-gray-50 p-5"
      style={{ backgroundColor: gameState === 102 ? '#FEE2E2' : !winner ? '#F9FAFB' : isWinner ? '#EFF6FF' : '#FEF2F2' }}
    >
      {gameState === 102 ? (
        <div>상대방의 연결이 종료되었습니다.</div>
      ) : !winner ? (
        isMyTurn ? (
          <div className="underline">당신 차례입니다!</div>
        ) : (
          <div>상대방 차례입니다.</div>
        )
      ) : (
        <>
          <div className="text-xl py-2">{isWinner ? '당신이 이겼습니다!' : '패배하였습니다.'}</div>
          <div
            className="text-center px-1 py-2 w-1/2 rounded-lg border border-gray-300 bg-gray-50 text-lg hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 transition"
            onClick={requestRematch}
          >
            재대결
          </div>

          {didIRequestRematch ? <div className="text-sm">재대결 요청을 보냈습니다.</div> : null}
          {didOpponentRequestRematch ? <div className="text-sm">재대결 요청을 받았습니다.</div> : null}
        </>
      )}
    </div>
  );
};

export default Information;
