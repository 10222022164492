import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import gomokuBoard from './../images/gomokuBoard.png';
import GomokuBoardDetect from './GomokuBoardDetect';
import MemoriedStone from './Stone';

interface lProps {
  isMyTurn: boolean;
  setIsMyTurn: (a: boolean) => void;
  setStoneNumber: (a: number) => void;
  isBlack: boolean;
  winner: string;
  username: string;
  room: string;
  socket: Socket;
}

const GomokuBoard = ({ isMyTurn, setIsMyTurn, setStoneNumber, isBlack, winner, username, room, socket }: lProps) => {
  const [gomokuInformation, setGomokuInformation] = useState<any[]>([]);
  const [cursorInBoard, setCursorInBoard] = useState(false);
  const [coord, setCoord] = useState({ x: 0, y: 0 });

  useEffect(() => {
    socket.on('new_stone', (data: { stone: { x: number; y: number }; username: string }) => {
      let new_stone = data.stone;
      let new_username = data.username;

      setGomokuInformation((gomokuInformation) => [...gomokuInformation, new_stone]);

      if (username !== new_username) {
        setIsMyTurn(true);
      }
    });

    socket.on('rematch', (data: { white: string }) => {
      setGomokuInformation([]);
    });
  }, [socket]);

  useEffect(() => {
    setStoneNumber(gomokuInformation.length);
  }, [gomokuInformation]);

  const handleBoardEnter = () => {
    setCursorInBoard(true);
  };

  const handleBoardLeave = () => {
    setCursorInBoard(false);
  };

  const handleBoardMove = (coord: { x: number; y: number }) => {
    //이미 돌이 존재하면 건너뜀
    setCoord(coord);
    // if (gomokuInformation.find((c) => c.x === coord.x && c.y === coord.y) === undefined) {
    // }
  };

  const handleBoardSelect = (coord: { x: number; y: number }) => {
    if (isMyTurn && gomokuInformation.find((c) => c.x === coord.x && c.y === coord.y) === undefined) {
      setIsMyTurn(false);

      let data = { stone: { x: coord.x, y: coord.y }, username: username, isBlack: isBlack, room: room };
      socket.emit('new_stone', data);
    }
  };

  return (
    <div className="flex font-sans m-0">
      <div className="h-[550px] w-[560px] relative">
        <img src={gomokuBoard} alt="logo" className="h-[550px] absolute" />
        <div className="relative omokboard h-[550px] w-[560px]">
          {gomokuInformation.map((stone, index) => (
            <MemoriedStone key={index} type={[index % 2 === 0 ? 'black' : 'white']} x={stone.x} y={stone.y} />
          ))}
          {gomokuInformation.length > 0 ? (
            <MemoriedStone type={['prev']} x={gomokuInformation[gomokuInformation.length - 1].x} y={gomokuInformation[gomokuInformation.length - 1].y} />
          ) : null}
          {isMyTurn && !winner && cursorInBoard ? <MemoriedStone type={[isBlack ? 'black' : 'white', 'hint']} x={coord.x} y={coord.y} /> : null}
        </div>
        {isMyTurn && !winner ? (
          <GomokuBoardDetect
            onBoardEnter={handleBoardEnter}
            onBoardMove={handleBoardMove}
            onBoardLeave={handleBoardLeave}
            onBoardSelect={handleBoardSelect}
          ></GomokuBoardDetect>
        ) : null}
      </div>
    </div>
  );
};

export default GomokuBoard;
