import React from 'react';
import { io, Socket } from 'socket.io-client';
// import Messages from './Messages';
// import SendMessage from './SendMessages';

interface lProps {
  countdownTime: number;
  isMyTurn: boolean;
}

const displayTime = (time: number) => {
  if (time <= 6 && time >= 1) {
    time = Math.floor(time);
    return time.toFixed(0);
  } else if (time > 0 && time < 1) {
    return time.toFixed(1);
  }
  return 0;
};

const Timer = ({ countdownTime, isMyTurn }: lProps) => {
  return (
    <div className="rounded-lg border border-gray-300 px-7 py-6">
      <div className="text-center text-gray-900 py-2 w-60 text-6xl">{displayTime(countdownTime)}</div>
      <div className="mt-3 flex h-3 rounded-lg border border-gray-300">
        <div
          className="h-full w-full overflow-hidden rounded-lg bg-blue-500"
          style={{
            width: countdownTime > 0 ? `${(countdownTime / (Number(process.env.REACT_APP_TIMELIMIT) + 1)) * 100}%` : '0%',
            backgroundColor: isMyTurn ? '#3B82F6' : '#EF4444',
          }}
        ></div>
      </div>
    </div>
  );
};

export default Timer;
