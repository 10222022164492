import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import GomokuBoard from './GomokuBoard';
import GomokuModal from './GomokuModal';

interface lProps {
  socket: Socket;
  username: string;
  room: string;
  gameState: number;
}

const Game = ({ socket, username, room, gameState }: lProps) => {
  const [isMyTurn, setIsMyTurn] = useState(false);
  const [isBlack, setIsBlack] = useState(false);
  const [winner, setWinner] = useState('');
  const [stoneNumber, setStoneNumber] = useState(0);

  useEffect(() => {
    if (username === 'Alice') {
      setIsBlack(false);
      setIsMyTurn(false);
    } else {
      setIsBlack(true);
      setIsMyTurn(true);
    }
  }, []);

  useEffect(() => {
    socket.on('game_end', (data: { win: string }) => {
      let win = data.win;
      setWinner(win);
    });
    socket.on('rematch', (data: { white: string }) => {
      if (username === data.white) {
        setIsBlack(false);
        setIsMyTurn(false);
      } else {
        setIsBlack(true);
        setIsMyTurn(true);
      }
      setWinner('');
    });
  }, [socket]);

  // useEffect(() => {
  //   if ((isBlack && winner === 'black') || (!isBlack && winner === 'white')) {
  //     console.log('win');
  //   } else {
  //     console.log('lose');
  //   }
  // }, [winner]);

  return (
    <div className="flex flex-row justify-center font-sans m-0 mt-12">
      <GomokuBoard
        isMyTurn={isMyTurn}
        setIsMyTurn={setIsMyTurn}
        setStoneNumber={setStoneNumber}
        isBlack={isBlack}
        winner={winner}
        username={username}
        room={room}
        socket={socket}
      ></GomokuBoard>
      <GomokuModal
        isMyTurn={isMyTurn}
        isBlack={isBlack}
        stoneNumber={stoneNumber}
        username={username}
        room={room}
        winner={winner}
        gameState={gameState}
        socket={socket}
      ></GomokuModal>
    </div>
  );
};

export default Game;
