import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
// import Messages from './Messages';
// import SendMessage from './SendMessages';

interface lProps {
  score: number[];
  username: string;
  isBlack: boolean;
}

const Score = ({ score, username, isBlack }: lProps) => {
  const [isAliceBlack, setIsAliceBlack] = useState(false);

  useEffect(() => {
    if ((username === 'Alice' && isBlack === true) || (username === 'Bob' && isBlack === false)) setIsAliceBlack(true);
    else setIsAliceBlack(false);
  }, [username, isBlack]);

  return (
    <div className="flex flex-col justify-center rounded-lg  text-center text-lg border-gray-300 p-5">
      <div className="text-2xl">점수</div>
      <div className="flex flex-row rounded-lg items-center border border-gray-300 h-9 mt-5">
        <div className="flex justify-center w-2/3 bg-gray-100 h-full items-center">
          {isAliceBlack ? '⚫' : '⚪'} {username === 'Alice' ? '나' : '상대'}
        </div>
        <div className="w-1/3">{score[0]}</div>
      </div>
      <div className="flex flex-row rounded-lg items-center border border-gray-300 h-9 mt-3">
        <div className="flex justify-center w-2/3 bg-gray-100 h-full items-center">
          {!isAliceBlack ? '⚫' : '⚪'} {!(username === 'Alice') ? '나' : '상대'}
        </div>
        <div className="w-1/3">{score[1]}</div>
      </div>
    </div>
  );
};

export default Score;
