import React from 'react';
import Header from './Header';
import Link from './Link';
import { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import Expired from './Expired';
import Game from './Game';

const newSocket = io(process.env.REACT_APP_SERVER ?? 'react_app_server_undefined', {
  transports: ['websocket'],
  upgrade: false,
  forceNew: true,
});

// let messageCount = 0;

function Home() {
  const [gameState, setGameState] = useState(0);
  /* gameState =
  0 : 아무것도 되지 않음
  1 : socket.io를 통해 두 명이 연결됨
  2 : 게임 시작
  */

  const [username, setUsername] = useState('');
  const [room, setRoom] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const roomNameInURL = urlParams.get('room');

    if (!roomNameInURL) {
      setUsername('Alice');
      newSocket.emit('new_room');
    } else if (roomNameInURL) {
      setRoom(roomNameInURL);
      setUsername('Bob'); // 방을 만든 사람은 Alice, 들어온 사람은 Bob
      newSocket.emit('join_room', { username: 'Bob', room: roomNameInURL });
    }

    setGameState(1);
  }, []);

  useEffect(() => {
    newSocket.on('receive_roomName', (data) => {
      let roomName = data;
      setRoom(roomName);
    });

    newSocket.on('game_start', (data) => {
      setGameState(2);
    });

    // 방이 없는 경우
    newSocket.on('room_not_exist', (data) => {
      setGameState(100); // 해당 주소가 존재하지 않을 경우 100으로 설정
    });
    // newSocket.on('room_expired_third_person', (data) => {
    //   setGameState(101); // 세번째 사람이 들어와서 방이 없어질경우 101으로 설정
    // });
    newSocket.on('room_expired_opponent_disconnected', (data) => {
      setGameState(102); // 상대방 나감
    });
  }, [newSocket]);

  return (
    <div>
      <Header></Header>
      {gameState === 100 ? (
        <Expired state={gameState}></Expired>
      ) : gameState === 2 || gameState === 102 ? (
        <Game socket={newSocket} username={username} room={room} gameState={gameState}></Game>
      ) : (
        <Link roomName={room}></Link>
      )}
    </div>
  );
}

export default Home;
